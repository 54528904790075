import {
    SET_NAVIGATION,
} from '../constants/Common';

const initState = {
  navConfig: [],
}

const common = (state = initState, action) => {
	switch (action.type) {
		case SET_NAVIGATION:
			return {
				...state,
				navConfig: action.navConfig
			}
        default:
            return state;
    }
}

export default common
import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import { ROLE } from "constants/AppConstatnt";
import { fetchUser } from "redux/actions";

function RouteInterceptor({ children, isAuthenticated, user, fetchUser, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isAuthenticated)
          return <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location }
            }}
          />
        else {
          if (!user) {
            fetchUser()
            return null
          }
          else if (user?.emailVerified === false) {
            return <Redirect to={`${AUTH_PREFIX_PATH}/verify-email`} />
          }
          else {
            return children;
          }
        }
      }
      }
    />
  );
}



export const AppViews = (props) => {
  const { authenticated, user, fetchUser } = props;

  useEffect(() => {
    if (authenticated) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated])

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route exact path='/' component={lazy(() => import(`./pages/top`))} />
        <Route exact path='/about' component={lazy(() => import(`./pages/about`))} />
        <Route exact path='/urllist' component={lazy(() => import(`./pages/urllist`))} />
        <Route exact path={`${APP_PREFIX_PATH}/collections`} component={lazy(() => import(`./collection/all`))} />
        <Route exact path={`${APP_PREFIX_PATH}/collections/category/:category`} component={lazy(() => import(`./collection/all`))} />
        <Route exact path={`${APP_PREFIX_PATH}/collections/detail/:id`} component={lazy(() => import(`./collection/detail`))} />

        <RouteInterceptor isAuthenticated={authenticated} {...props}>
          <Route path={`${APP_PREFIX_PATH}/mypage`} component={lazy(() => import(`./mypage`))} />
          <Route path={`${APP_PREFIX_PATH}/setting`} component={lazy(() => import(`./setting`))} />

          <Route exact path={`${APP_PREFIX_PATH}/collections/owned`} component={lazy(() => import(`./collection/owned`))} />
          <Route exact path={`${APP_PREFIX_PATH}/collections/favorite`} component={lazy(() => import(`./collection/favorite`))} />
          <Route exact path={`${APP_PREFIX_PATH}/manage/collection`} component={lazy(() => import(`./collection/manage/list`))} />
          <Route exact path={`${APP_PREFIX_PATH}/manage/collection/create`} component={lazy(() => import(`./collection/manage/create`))} />
          <Route exact path={`${APP_PREFIX_PATH}/manage/collection/edit/:id`} component={lazy(() => import(`./collection/manage/edit`))} />
          {
            (user?.role === ROLE.ADMIN) &&
            <Route>
              <Route exact path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
              <Route exact path={`${APP_PREFIX_PATH}/categories`} component={lazy(() => import(`./category`))} />
              <Route exact path={`${APP_PREFIX_PATH}/category-groups`} component={lazy(() => import(`./category/group/index`))} />
            </Route>
          }
        </RouteInterceptor>
      </Switch>
    </Suspense>

  )
}

const mapStateToProps = ({ theme, auth, appStore }) => {
  const { locale, direction } = theme;
  const { authenticated } = auth;
  const { user } = appStore;
  return { locale, direction, authenticated, user }
};

export default connect(mapStateToProps, { fetchUser })(React.memo(AppViews));

const AuthApi = (httpClient) => ({
	login(email, password) {
		return httpClient.post(`user/auth/login`, { email, password })
	},

	loginWithGoogle(email) {
		return httpClient.post(`user/auth/loginWithGoogle`, { email })
	},

	signUp(email, password) {
		return httpClient.post(`user/auth/signUp`, { email, password });
	},

	signUpWithGoogle(email) {
		return httpClient.post(`user/auth/signUpWithGoogle`, { email });
	},

	//----------Auth Management---------------//
	sendLinkOfResetPassword(email) {
		return httpClient.post(`user/auth/sendLinkOfResetPassword`, {
			email
		})
	},

	resetPassword(token, password) {
		return httpClient.post(`user/auth/resetPassword/${token}`, {
			password
		});
	},

	checkLinkOfResetPassword(token) {
		return httpClient.fetch(`user/auth/checkLinkOfResetPassword/${token}`);
	},


	changePassword(email, password, newPassword) {
		return httpClient.post(`user/auth/changePassword`, {
			email,
			password,
			newPassword
		})
	},

	sendLinkOfVerifyEmail(email, new_email) {
		return httpClient.post(`user/auth/sendLinkOfVerifyEmail`, {
			email,
			new_email
		});
	},

	verifyEmail(token) {
		return httpClient.post(`user/auth/verifyEmail/${token}`);
	},

	sendLinkOfChangeEmail(email, new_email) {
		return httpClient.post(`user/auth/sendLinkOfChangeEmail`, {
			email,
			new_email
		});
	},

	changeEmail(token) {
		return httpClient.post(`user/auth/changeEmail/${token}`);
	},

	checkDuplicatedEmail(email) {
		return httpClient.post(`user/auth/checkDuplicatedEmail/${email}`);
	},
})


export default AuthApi
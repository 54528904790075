import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	LOAD_CATEGORIES,
	LOAD_USER_INFO,
	LOAD_CATEGORY_GROUPS,
} from 'redux/constants/App';
import {
	setUser,
	setCategories,
	setCategoryGroups,
} from "redux/actions";
import api from 'api';

export function* loadUserInfo() {
	yield takeEvery(LOAD_USER_INFO, function* () {
		const { data } = yield call(api.user.fetchUser);
		yield put(setUser(data));
	});
}

export function* loadCategories() {
	yield takeEvery(LOAD_CATEGORIES, function* () {
		const { data } = yield call(api.admin.fetchCategories);
		yield put(setCategories(data.categories));
	});
}

export function* loadCategoryGroups() {
	yield takeEvery(LOAD_CATEGORY_GROUPS, function* () {
		const { data } = yield call(api.admin.fetchCategoryGroups);
		yield put(setCategoryGroups(data.categoryGroups));
	});
}

export default function* rootSaga() {
	yield all([
		fork(loadUserInfo),
		fork(loadCategories),
		fork(loadCategoryGroups),
	]);
}

import HttpClient from 'api/httpClient'
import admin from 'api/endpoints/admin'
import user from 'api/endpoints/user'
import auth from 'api/endpoints/auth'

const httpClient = new HttpClient()

const api = {
  auth: auth(httpClient),
  admin: admin(httpClient),
  user: user(httpClient),
}

export default api
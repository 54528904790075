const AdminApi = (httpClient) => ({
    fetchUsers() {
        return httpClient.fetch(`admin/users/all`);
    },

    updateUser(userId, payload) {
        return httpClient.put(`admin/users/${userId}`, payload);
    },

    deleteUser(userId) {
        return httpClient.delete(`admin/users/${userId}`);
    },

    fetchDownloadCollections() {
        return httpClient.fetch(`admin/collection/download`);
    },

    bulkUploadCollections(payload) {
        return httpClient.post(`admin/collection/bulk_upload`, payload);
    },

    deleteAllCollections() {
        return httpClient.delete(`admin/collection/deleteAll`);
    },

    fetchCategories() {
        return httpClient.fetch(`category/all`);
    },

    addCategory(payload) {
        return httpClient.post(`admin/category/store`, payload);
    },

    updateCategory(id, payload) {
        return httpClient.put(`admin/category/${id}`, payload);
    },

    deleteCategory(id) {
        return httpClient.delete(`admin/category/${id}`);
    },

    fetchCategoryGroups() {
        return httpClient.fetch(`category-group/all`);
    },

    addCategoryGroup(payload) {
        return httpClient.post(`admin/category-group/store`, payload);
    },

    updateCategoryGroup(id, payload) {
        return httpClient.put(`admin/category-group/${id}`, payload);
    },

    deleteCategoryGroup(id) {
        return httpClient.delete(`admin/category-group/${id}`);
    },
})


export default AdminApi;
import {
	LOADED_USER_INFO_SUCCESS,
	LOADED_CATEGORIES_SUCCESS,
	LOADED_CATEGORY_GROUPS_SUCCESS,
} from 'redux/constants/App';

const initState = {
	user: {},
	categories: [],
	categoryGroups: [],
}

const appStore = (state = initState, action) => {
	switch (action.type) {
		case LOADED_USER_INFO_SUCCESS:
			return {
				...state,
				user: action.payload,
			}
		case LOADED_CATEGORIES_SUCCESS:
			return {
				...state,
				categories: [...action.payload]
			}
		case LOADED_CATEGORY_GROUPS_SUCCESS:
			return {
				...state,
				categoryGroups: [...action.payload]
			}
		default:
			return state;
	}
}

export default appStore
export const ADMIN = 'admin'
export const USER = 'user'

export const ROLE = {
    ADMIN,
    USER,
}

export const CREATE = 'create'
export const EDIT = 'edit'
export const FAVORITE = 'favorite'
export const CATEGORY = 'category'
export const ALL = 'all'
export const OWNED = 'owend'

export const MODE = {
    CREATE,
    EDIT,
    FAVORITE,
    CATEGORY,
    ALL,
    OWNED
}
import {
  DashboardOutlined,
  AppstoreOutlined,
  SettingOutlined,
  UserOutlined,
  HeartOutlined,
  UnorderedListOutlined,
  PlusCircleOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';


const AdminNavTree = [{
  key: 'admin',
  path: `${APP_PREFIX_PATH}`,
  title: 'sidenav.empty',
  localization: true,
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'admin-users',
      path: `${APP_PREFIX_PATH}/users`,
      title: 'sidenav.users',
      localization: true,
      icon: UserOutlined,
      breadcrumb: true,
      submenu: [],
    },
    {
      key: 'admin-category',
      path: `${APP_PREFIX_PATH}/category`,
      title: 'sidenav.manage.category',
      localization: true,
      icon: UnorderedListOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'admin-category-list',
          path: `${APP_PREFIX_PATH}/categories`,
          title: 'sidenav.manage.category.list',
          localization: true,
          icon: '',
          breadcrumb: true,
          submenu: [],
        },
        {
          key: 'admin-category-group',
          path: `${APP_PREFIX_PATH}/category-groups`,
          title: 'sidenav.manage.category.group',
          localization: true,
          icon: '',
          breadcrumb: true,
          submenu: [],
        }
      ],
    },
  ]
}]

const UserNavTree = [{
  key: 'user',
  path: `${APP_PREFIX_PATH}`,
  title: 'sidenav.empty',
  localization: true,
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'user-mypage',
      path: `${APP_PREFIX_PATH}/mypage`,
      title: 'sidenav.mypage',
      localization: true,
      icon: HomeOutlined,
      breadcrumb: true,
      submenu: [],
    },
    {
      key: 'user-collections-favourite',
      path: `${APP_PREFIX_PATH}/collections/favorite`,
      title: 'sidenav.collections.favorite',
      localization: true,
      icon: HeartOutlined,
      breadcrumb: true,
      submenu: [],
    },
    {
      key: 'user-collections-owned',
      path: `${APP_PREFIX_PATH}/collections/owned`,
      title: 'sidenav.collections.owned',
      localization: true,
      icon: PlusCircleOutlined,
      breadcrumb: true,
      submenu: [],
    },
    {
      key: 'user-setting',
      path: `${APP_PREFIX_PATH}/setting`,
      title: 'sidenav.setting',
      localization: true,
      icon: SettingOutlined,
      breadcrumb: true,
      submenu: [],
    },
    {
      key: 'user-collection',
      path: `${APP_PREFIX_PATH}/manage/collection`,
      title: 'sidenav.manage.collection',
      localization: true,
      icon: AppstoreOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'user-collection-list',
          path: `${APP_PREFIX_PATH}/manage/collection`,
          title: 'sidenav.manage.collection.list',
          localization: true,
          icon: '',
          breadcrumb: true,
          submenu: [],
        },
        {
          key: 'user-collection-add',
          path: `${APP_PREFIX_PATH}/manage/collection/create`,
          title: 'sidenav.manage.collection.create',
          localization: true,
          icon: '',
          breadcrumb: false,
          submenu: [],
        },
      ]
    },
  ]
}]

const appsNavTree = [{
  key: 'apps',
  path: `${APP_PREFIX_PATH}`,
  title: 'sidenav.empty',
  localization: true,
  icon: AppstoreOutlined,
  breadcrumb: true,
  submenu: [],
}]

const homeNavTree = [{
  key: 'apps',
  path: `${APP_PREFIX_PATH}`,
  title: 'sidenav.empty',
  localization: true,
  icon: AppstoreOutlined,
  breadcrumb: true,
  submenu: [],
}]

export const navigationHomeConfig = [...homeNavTree];
export const navigationUserConfig = [...appsNavTree, ...UserNavTree];
export const navigationAdminConfig = [...appsNavTree, ...UserNavTree, ...AdminNavTree];

export default navigationAdminConfig;

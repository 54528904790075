import { combineReducers } from 'redux';
import Auth from './Auth';
import AppStore from './App';
import Theme from './Theme';
import Common from './Common';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    appStore: AppStore,
    common: Common,
});

export default reducers;
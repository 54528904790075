import {
  LOAD_USER_INFO,
  LOADED_USER_INFO_SUCCESS,
  LOAD_CATEGORIES,
  LOADED_CATEGORIES_SUCCESS,
  LOAD_CATEGORY_GROUPS,
  LOADED_CATEGORY_GROUPS_SUCCESS,
} from '../constants/App';

export const fetchUser = () => {
  return {
    type: LOAD_USER_INFO,
  }
};

export const setUser = (payload) => {
  return {
    type: LOADED_USER_INFO_SUCCESS,
    payload,
  }
};

export const fetchCategories = () => {
  return {
    type: LOAD_CATEGORIES,
  }
}

export const setCategories = (payload) => {
  return {
    type: LOADED_CATEGORIES_SUCCESS,
    payload,
  }
};

export const fetchCategoryGroups = () => {
  return {
    type: LOAD_CATEGORY_GROUPS,
  }
}

export const setCategoryGroups = (payload) => {
  return {
    type: LOADED_CATEGORY_GROUPS_SUCCESS,
    payload,
  }
};
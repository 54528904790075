import React from 'react'
import { Switch, Route, } from "react-router-dom";
import AuthViews from 'views/auth';
import Loading from 'components/shared-components/Loading';
import { useThemeSwitcher } from "react-css-theme-switcher";
import {
	Layout,
} from "antd";

export const AuthLayout = () => {
	const { status } = useThemeSwitcher();
	if (status === 'loading') {
		return <Loading cover="page" />;
	}
	return (
		<Layout>
			<div className="auth-container">
				<Switch>
					<Route path="" component={AuthViews} />
				</Switch>
			</div>
		</Layout>
	)
}


export default AuthLayout

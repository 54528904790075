import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { GET_NAVIGATION } from 'redux/constants/Common';
import { setNavigationConfig } from "redux/actions";
import { message } from 'antd';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import navigationAdminConfig, { navigationHomeConfig, navigationUserConfig } from "configs/NavigationConfig";
import api from 'api';
import camelCase from 'utils/camelcase';
import store from 'redux/store';
import { ROLE } from 'constants/AppConstatnt';
import { AppstoreOutlined } from '@ant-design/icons';

export function* fetchNavigationConfig() {
	yield takeEvery(GET_NAVIGATION, function* () {
		try {
			const response = yield call(api.admin.fetchCategoryGroups);
			if (response.data.statusCode === 200) {
				const { categoryGroups } = response.data;
				let categoryNav = categoryGroups.map(categoryGroup => (
					{
						key: `apps-collection-${categoryGroup.id}`,
						path: `${APP_PREFIX_PATH}/collections/category/${categoryGroup.categories[0]?.slug}`,
						title: {
							ja: categoryGroup[camelCase('ja_label')] || '',
							en: categoryGroup[camelCase('en_label')] || '',
							zh: categoryGroup[camelCase('zh_label')] || '',
						},
						localization: false,
						breadcrumb: false,
						icon: AppstoreOutlined,
						submenu: [
							...categoryGroup.categories.map(category => (
								{
									key: `apps-collection-${category.slug}`,
									path: `${APP_PREFIX_PATH}/collections/category/${category.slug}`,
									title: {
										ja: category[camelCase('ja_label')] || '',
										en: category[camelCase('en_label')] || '',
										zh: category[camelCase('zh_label')] || '',
									},
									localization: false,
									breadcrumb: false,
									submenu: [],
								}
							))
						],
					}
				));

				categoryNav.unshift({
					key: `apps-collection-all`,
					path: `${APP_PREFIX_PATH}/collections`,
					title: 'sidenav.collections.all',
					localization: true,
					icon: AppstoreOutlined,
					breadcrumb: true,
					submenu: []
				})

				const token = store.getState().auth.token;
				const user = store.getState().appStore.user;

				const _navigation = token ?
					(user?.role === ROLE.ADMIN ? navigationAdminConfig : navigationUserConfig)
					: navigationHomeConfig;
				if (token) {
					_navigation.at(0).submenu = [...categoryNav, ..._navigation.at(0).submenu];
				} else {
					_navigation.at(0).submenu = categoryNav;
				}

				yield put(setNavigationConfig(_navigation));
			} else {
				message.error(response.data.message);
			}
		} catch (err) {
			message.error(err.toString());
		}
	});
}


export default function* rootSaga() {
	yield all([
		fork(fetchNavigationConfig),
	]);
}

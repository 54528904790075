import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Okaimono';
export const API_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = '';
export const AUTH_PREFIX_PATH = '/auth';
export const APP_ENTRY_PATH = '/mypage';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'ja',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'dark',
	direction: DIR_LTR
};

export const FirebaseConfig = {
	apiKey: 'AIzaSyCs0QmiLFRpv4TC5yEtsUeb1WDTKxX6XIk',
	authDomain: 'okaimono-a1d12.firebaseapp.com',
	projectId: 'okaimono-a1d12',
	storageBucket: 'okaimono-a1d12.appspot.com',
	messagingSenderId: '713041628070',
	appId: '1:713041628070:web:d5760f9263764767a71d67',
	measurementId: 'G-LR2K01ZS2G'
};
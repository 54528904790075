const UserApi = (httpClient) => ({
	//-----------Profile----------//
	updateProfile(payload) {
		return httpClient.put(`user/update/profile`, payload)
	},

	updateLinkedGmail(payload) {
		return httpClient.put(`user/update/linkedGmail`, payload)
	},
	//-----------Collection----------//
	addCollection(payload) {
		return httpClient.post(`user/collection/store`, payload);
	},

	updateCollection(coId, payload) {
		return httpClient.put(`user/collection/update/${coId}`, payload);
	},

	fetchCollections(authenticated, query) {
		const apiUri = authenticated ?
			`user/collections/all` :
			`collections/all`

		return httpClient.fetch(apiUri, {}, { query })
	},

	fetchCollectionsByCategory(slug, authenticated, query) {
		const apiUri = authenticated ?
			`user/collections/category/${slug}` :
			`collections/category/${slug}`

		return httpClient.fetch(apiUri, {}, { query })
	},

	detailCollection(id, authenticated) {
		const apiUri = authenticated ?
			`user/collections/get/${id}` :
			`collections/get/${id}`

		return httpClient.fetch(apiUri)
	},

	fetchFavoriteCollections(query) {
		return httpClient.fetch(`user/collections/favorite`, {}, { query })
	},

	fetchMyCollections(query) {
		return httpClient.fetch(`user/collections/owned`, {}, { query });
	},

	fetchCreatedCollections() {
		return httpClient.fetch(`user/collections/created`);
	},

	fetchAnalyticData() {
		return httpClient.fetch(`user/collections/analytic`);
	},

	deleteCollection(id) {
		return httpClient.delete(`user/collections/${id}`);
	},

	//-----------Favorite----------//
	setCollectionLike(id) {
		return httpClient.post(`user/collections/${id}/like`);
	},

	setCollectionUnLike(id) {
		return httpClient.post(`user/collections/${id}/unlike`);
	},

	setCollectionOwn(id) {
		return httpClient.post(`user/collections/${id}/own`);
	},

	fetchUser() {
		return httpClient.fetch(`user/me`);
	}
});

export default UserApi;

import {
  GET_NAVIGATION,
  SET_NAVIGATION,
} from '../constants/Common';

export function getNavigationConfig() {
  return {
    type: GET_NAVIGATION,
  };
}

export function setNavigationConfig(navConfig) {
  return {
    type: SET_NAVIGATION,
    navConfig
  };
}
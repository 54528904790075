import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getNavigationConfig,
  fetchCategories,
  fetchCategoryGroups,
} from 'redux/actions';
import AppLayout from "layouts/app-layout.js";
import AuthLayout from 'layouts/auth-layout.js';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import AppViews from "./app";

export const Views = (props) => {
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useEffect(() => {
    props.fetchCategories();
    props.fetchCategoryGroups();
    props.getNavigationConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>

          <AppLayout direction={direction} location={location}>
            <AppViews />
          </AppLayout>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme }) => {
  const { locale, direction } = theme;
  return { locale, direction }
};

export default withRouter(connect(mapStateToProps, {
  fetchCategories,
  fetchCategoryGroups,
  getNavigationConfig,
})(Views));
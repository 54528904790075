import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNUP_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK,
} from '../constants/Auth';
import {
	showAuthMessage,
	// authenticated,
	signOutSuccess,
	// signUpSuccess,
	// signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated
} from "../actions/Auth";
import { APP_ENTRY_PATH } from 'configs/AppConfig';

import {
	setUser,
} from "../actions";

import FirebaseService from 'services/firebase'
import api from 'api';
import Jwt from 'services/jwt';

export function* signInWithEmail() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
		const { email, password } = payload;
		try {
			const { data } = yield call(api.auth.login, email, password);
			if (data.statusCode === 200) {
				Jwt.setToken(data.token);
				// yield put(authenticated(data.token));
				yield put(setUser(data.user));
				window.location.href = APP_ENTRY_PATH;
			} else {
				yield put(showAuthMessage(data.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err.toString()));
		}
	});
}

export function* signUpWithEmail() {
	yield takeEvery(SIGNUP, function* ({ payload }) {
		const { email, password } = payload;
		try {
			const { data } = yield call(api.auth.signUp, email, password);
			if (data.statusCode === 200) {
				Jwt.setToken(data.token);
				// yield put(signUpSuccess(data.token));
				yield put(setUser(data.user));
				window.location.href = APP_ENTRY_PATH;
			} else {
				yield put(showAuthMessage(data.message));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

export function* signInWithFBGoogle() {
	yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				const { data } = yield call(api.auth.loginWithGoogle, user.user.email);
				if (data.statusCode === 200) {
					Jwt.setToken(data.token);
					// yield put(signInWithGoogleAuthenticated(data.token));
					yield put(setUser(data.user));
					window.location.href = APP_ENTRY_PATH;
				} else {
					yield put(showAuthMessage(data.message));
				}
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signUpWithFBGoogle() {
	yield takeEvery(SIGNUP_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				const { data } = yield call(api.auth.signUpWithGoogle, user.user.email);
				if (data.statusCode === 200) {
					Jwt.setToken(data.token);
					// yield put(signUpSuccess(data.token));
					yield put(setUser(data.user));
					window.location.href = APP_ENTRY_PATH;
				} else {
					yield put(showAuthMessage(data.message));
				}
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
	yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				Jwt.setToken(user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				Jwt.logout();
				window.location.reload();
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(signInWithEmail),
		fork(signUpWithEmail),
		fork(signInWithFBGoogle),
		fork(signUpWithFBGoogle),
		fork(signInWithFacebook),
		fork(signOut),
	]);
}
